import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Webinar from "./Webinar";

const Layout = () => {
  return (
    <>
      <Routes>
        <Route path="/webinar/:webinar_id" element={<Webinar />} />
      </Routes>
    </>
  );
};

export default Layout;
