import './App.css';
import {useEffect, useState} from "react";
import {OpenVidu} from "openvidu-browser";
import {useParams} from "react-router-dom";
import axios from "axios";
import {Carousel} from "react-bootstrap";

function Webinar() {
  const params = useParams();
  const [session, setSession] = useState();
  const [localSwitchOnAir, setLocalSwitchOnAir] = useState(false);
  const [localThumbnail, setLocalThumbnail] = useState();
  const [localActiveSlide, setLocalActiveSlide] = useState();
  const [screenSessionId, setScreenSessionId] = useState();

  const [switchCornerCamera, setSwitchCornerCamera] = useState(false);
  const [switchFullScreenCamera, setSwitchFullScreenCamera] = useState(false);
  const [switchThumbnail, setSwitchThumbnail] = useState(true);
  const [switchCarousel, setSwitchCarousel] = useState(false);
  const [switchScreen, setSwitchScreen] = useState(false);

  const [localSlides, setLocalSlides] = useState();
  let webinarId = params?.webinar_id;


  useEffect(() => {
    const WEBINAR_CONTROLLER_SERVER = process.env.REACT_APP_WEBINAR_CONTROLLER_SERVER;
    const OV = new OpenVidu();
    const sess = OV.initSession();
    const urlGetSession = WEBINAR_CONTROLLER_SERVER + '/api/v1/session/webinar/info/';
    const urlGetToken = WEBINAR_CONTROLLER_SERVER + '/api/v1/session/token/'

    axios.get(urlGetSession + webinarId).then((response) => {
      const sessionId = response.data.settings.main_session;
      setLocalThumbnail(response.data.thumbnail);
      setLocalSlides(response.data.slides);
      console.log('sessionId', sessionId);

      axios.get(urlGetToken + sessionId).then((response) => {
        const token = response.data.token;
        console.log('token', token);

        sess.connect(token)
          .then(() => {
            console.log('connected', token);
          });

        // On every asynchronous exception...
        sess.on('exception', (exception) => {
          console.warn(exception);
        });

        setSession(sess);
      });
    });
  }, [webinarId]);

  useEffect(() => {
    if (session !== undefined) {
      session.on('signal:webinar-commands', (event) => {
        const data = JSON.parse(event.data);
        setSwitchCornerCamera(data.cornerCamera);
        setLocalSwitchOnAir(data.switchOnAir);
        setLocalActiveSlide(data.presentationIndex);
        setScreenSessionId(data.screenSessionId);

        if (data.currentActive === 'presentation') {
          setSwitchThumbnail(false);
          setSwitchCarousel(true);
          setSwitchFullScreenCamera(false);
          setSwitchScreen(false);
        }
        if (data.currentActive === 'thumbnail') {
          setSwitchThumbnail(true);
          setSwitchCarousel(false);
          setSwitchFullScreenCamera(false);
          setSwitchScreen(false);
        }

        if (data.currentActive === 'camera') {
          setSwitchThumbnail(false);
          setSwitchCarousel(false);
          setSwitchFullScreenCamera(true);
          setSwitchScreen(false);
        }

        if (data.currentActive === 'screen') {
          setSwitchThumbnail(false);
          setSwitchCarousel(false);
          setSwitchFullScreenCamera(false);
          setSwitchScreen(true);
        }
      });
    }
  }, [session])

  useEffect(() => {
    console.log('localSwitchOnAir', localSwitchOnAir)
    if (localSwitchOnAir === true) {
          console.log('Start recording');
        } else {
          console.log('Stop recording');
        }

  }, [localSwitchOnAir])

  return (
    <div className="App" id={'main'}>
      <Camera session={session} fullScreen={switchFullScreenCamera} />
      {switchThumbnail && <ThumbnailWebinar thumbnail={localThumbnail} />}
      {switchCarousel && <UncontrolledCarousel slides={localSlides} active={localActiveSlide} />}
      {switchScreen && <Screen sessionId={screenSessionId} />}
    </div>
  );
}

const Screen = ({sessionId}) => {
  //const [session, setSession] = useState();
  useEffect(() => {
    const WEBINAR_CONTROLLER_SERVER = process.env.REACT_APP_WEBINAR_CONTROLLER_SERVER;
    const OV = new OpenVidu();
    const session = OV.initSession();
    const urlGetToken = WEBINAR_CONTROLLER_SERVER + '/api/v1/session/token/'

    if (sessionId !== undefined) {

      console.log('Screen sessionId', sessionId);

      axios.get(urlGetToken + sessionId).then((response) => {
        const token = response.data.token;
        console.log('Screen token', token);

        session.connect(token)
          .then(() => {
            console.log('Screen connected', token);
          });

        // On every asynchronous exception...
        session.on('exception', (exception) => {
          console.warn(exception);
        });

        session.on('streamCreated', (event) => {
          const subscriber = session.subscribe(event.stream, undefined);
          const video = subscriber.createVideoElement('screen', 'APPEND');
          video.muted = true;
          video.autoplay = true;
          console.log('----------------- Start Screen -----------------');
        });

        session.on('streamDestroyed', (event) => {
          console.log('----------------streamDestroyed--------------')
          event.preventDefault();
          session.unsubscribe(event.stream.streamManager);
          console.log('----------------- Stop Screen -----------------');
        });

      });
    }
  }, [sessionId]);
  return <div className={'swiper-slide'} id="screen" />
}

const UncontrolledCarousel = ({slides, active}) => {

  return (
    <Carousel
      activeIndex={active}
      fade
      controls={false}
      indicators={false}
      interval={null}
      keyboard={false}
      touch={false}
      wrap={true}
    >
      {slides && slides.length && slides.map((slide, key) => {
        return <Carousel.Item key={key}>
          <img
            className="d-block w-100"
            src={slide.tus}
            alt={slide.tus}
          />
        </Carousel.Item>
      })}
    </Carousel>
  );
}

const ThumbnailWebinar = ({thumbnail}) => {
  return <div className={'swiper-slide'}>
    <img src={thumbnail} alt={'#'} />
  </div>
}

const Camera = ({session, fullScreen}) => {
  useEffect(() => {
    if (session !== undefined) {
      session.on('streamCreated', (event) => {
        const subscriber = session.subscribe(event.stream, undefined);
        const video = subscriber.createVideoElement('camera', 'APPEND');
        video.muted = false;
        video.autoplay = true;
        console.log('----------------- Start Camera -----------------');
      });
    }
  }, [session]);

  useEffect(() => {
    if (session !== undefined) {
      session.on('streamDestroyed', (event) => {
        console.log('----------------streamDestroyed--------------')
        event.preventDefault();
        session.unsubscribe(event.stream.streamManager);
        console.log('----------------- Stop Camera -----------------');
      });
    }
  }, [session]);

  return <div id={'camera'} className={fullScreen ? 'fullscreen-camera' : 'corner-camera'} />
}

export default Webinar;
